

.row-common {
  display: flex;
  flex-direction: row;
}
.row-reverse-common {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 1024px)
{
  .row-responsive {
    flex-direction: column;
  }
}

