

.col-common {
  display: flex;
  flex-direction: column;
}
.col-reverse-common {
  display: flex;
  flex-direction: column-reverse;
}
@media (max-width: 1024px)
{
  .col-responsive {
    flex-direction: row;
  }
}

